const SettingIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2883 4H13.8704C13.4546 4 13.0822 4.25732 12.9351 4.64625L12.3614 6.16296C11.8537 7.50558 10.4659 8.30037 9.05096 8.05899L7.4021 7.77772C6.99069 7.70754 6.57895 7.90025 6.36929 8.26111L4.35616 11.726C4.14281 12.0932 4.18467 12.5551 4.46055 12.878L5.43934 14.0235C6.40859 15.1578 6.39692 16.8321 5.41195 17.9528L4.49234 18.9991C4.20026 19.3315 4.16129 19.8162 4.39653 20.191L6.76966 23.9712C6.98636 24.3164 7.39085 24.4956 7.79209 24.424L9.37842 24.1413C10.7373 23.899 12.0861 24.6153 12.6465 25.8768L13.3259 27.406C13.4863 27.7672 13.8445 28 14.2397 28H18.3428C18.7546 28 19.1243 27.7476 19.2742 27.364L19.7594 26.1225C20.262 24.8363 21.5778 24.0594 22.9468 24.2404L24.5562 24.4532C24.9632 24.507 25.3619 24.3065 25.5613 23.9476L26.6938 21.9098C27.4758 20.5025 27.2813 18.7544 26.2091 17.5534C25.6335 16.9087 25.5682 15.9558 26.0504 15.2386L27.6477 12.863C27.8629 12.5429 27.8748 12.1275 27.6783 11.7956L25.5806 8.25194C25.3698 7.89592 24.9616 7.70645 24.5536 7.7753L22.9993 8.03763C21.517 8.28779 20.0785 7.40232 19.6341 5.9663L19.2436 4.70439C19.114 4.28557 18.7267 4 18.2883 4Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M20.1822 16.1526C20.1822 18.4005 18.342 20.2373 16.054 20.2373C13.766 20.2373 11.9258 18.4005 11.9258 16.1526C11.9258 13.9046 13.766 12.0678 16.054 12.0678C18.342 12.0678 20.1822 13.9046 20.1822 16.1526Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default SettingIcon;
