const PhotoIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="10.461"
        width="18.5376"
        height="16.4835"
        rx="3"
        stroke={color}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8053 6.03196L24.2499 7.92963C25.3419 8.09614 26.1209 9.12024 25.99 10.217L24.7516 20.591C24.7218 20.8404 24.6475 21.0722 24.5377 21.2794V23.9445C24.5377 23.945 24.5377 23.9456 24.5377 23.9461C25.7024 23.4018 26.5625 22.2853 26.7287 20.8925L27.9671 10.5185C28.229 8.32496 26.6709 6.27675 24.487 5.94373L12.0423 4.04606C9.85845 3.71304 7.87578 5.22131 7.61392 7.41487L7.36365 9.51133C7.57089 9.4782 7.78344 9.46099 8.00002 9.46099H9.38279L9.59106 7.71636C9.72199 6.61958 10.7133 5.86545 11.8053 6.03196Z"
        fill={color}
      />
      <path
        d="M4.50024 22.4445L7.22882 20.2415C9.11363 18.7198 11.8082 18.7316 13.6796 20.2698V20.2698C15.4812 21.7507 18.0571 21.8229 19.9389 20.4454L23.7873 17.6281"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle cx="16.5" cy="15.4445" r="1.9" stroke={color} strokeWidth="1.2" />
    </svg>
  );
};

export default PhotoIcon;
