const PrintIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.125 15.5C15.3087 15.5 15.486 15.5675 15.6233 15.6895C15.7606 15.8116 15.8483 15.9798 15.8698 16.1622L15.875 16.25V19.25C15.875 19.4337 15.8075 19.611 15.6855 19.7483C15.5634 19.8856 15.3952 19.9733 15.2128 19.9948L15.125 20H9.125C8.9413 20 8.764 19.9325 8.62672 19.8105C8.48944 19.6884 8.40174 19.5202 8.38025 19.3378L8.375 19.25V16.25C8.37502 16.0663 8.44247 15.889 8.56454 15.7517C8.68661 15.6144 8.85481 15.5267 9.03725 15.5052L9.125 15.5H15.125ZM17.375 8.75C17.9717 8.75 18.544 8.98705 18.966 9.40901C19.3879 9.83097 19.625 10.4033 19.625 11V16.25C19.625 16.6478 19.467 17.0294 19.1857 17.3107C18.9044 17.592 18.5228 17.75 18.125 17.75H17.375V15.5C17.375 15.1022 17.217 14.7206 16.9357 14.4393C16.6544 14.158 16.2728 14 15.875 14H8.375C7.97718 14 7.59564 14.158 7.31434 14.4393C7.03304 14.7206 6.875 15.1022 6.875 15.5V17.75H6.125C5.72718 17.75 5.34564 17.592 5.06434 17.3107C4.78304 17.0294 4.625 16.6478 4.625 16.25V11C4.625 10.4033 4.86205 9.83097 5.28401 9.40901C5.70597 8.98705 6.27826 8.75 6.875 8.75H17.375ZM15.875 10.25H14.375C14.1838 10.2502 14 10.3234 13.861 10.4546C13.722 10.5859 13.6383 10.7652 13.6271 10.956C13.6159 11.1469 13.678 11.3348 13.8007 11.4814C13.9234 11.628 14.0974 11.7222 14.2873 11.7448L14.375 11.75H15.875C16.0662 11.7498 16.25 11.6766 16.389 11.5454C16.528 11.4141 16.6117 11.2348 16.6229 11.044C16.6341 10.8531 16.572 10.6652 16.4493 10.5186C16.3266 10.372 16.1526 10.2778 15.9628 10.2552L15.875 10.25ZM15.875 5C16.0739 5 16.2647 5.07902 16.4053 5.21967C16.546 5.36032 16.625 5.55109 16.625 5.75V7.25H7.625V5.75C7.625 5.55109 7.70402 5.36032 7.84467 5.21967C7.98532 5.07902 8.17609 5 8.375 5H15.875Z"
        fill={color}
      />
    </svg>
  );
};

export default PrintIcon;
