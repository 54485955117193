const DownloadIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5736 13.0588H19.6429V7.17647C19.6429 6.52941 19.0964 6 18.4286 6H13.5714C12.9036 6 12.3571 6.52941 12.3571 7.17647V13.0588H10.4264C9.34571 13.0588 8.79929 14.3294 9.56429 15.0706L15.1379 20.4706C15.6114 20.9294 16.3764 20.9294 16.85 20.4706L22.4236 15.0706C23.1886 14.3294 22.6543 13.0588 21.5736 13.0588ZM7.5 24.8235C7.5 25.4706 8.04643 26 8.71429 26H23.2857C23.9536 26 24.5 25.4706 24.5 24.8235C24.5 24.1765 23.9536 23.6471 23.2857 23.6471H8.71429C8.04643 23.6471 7.5 24.1765 7.5 24.8235Z"
        fill={color}
      />
    </svg>
  );
};

export default DownloadIcon;
