const CheckedIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1741 21.1222L8.03177 16.9828C7.80857 16.7598 7.50584 16.6345 7.19019 16.6345C6.87453 16.6345 6.5718 16.7598 6.3486 16.9828C6.12539 17.2059 6 17.5084 6 17.8238C6 17.98 6.03079 18.1347 6.0906 18.279C6.15041 18.4233 6.23808 18.5544 6.3486 18.6648L11.3384 23.6511C11.804 24.1163 12.5561 24.1163 13.0216 23.6511L25.6514 11.0303C25.8746 10.8073 26 10.5048 26 10.1893C26 9.8739 25.8746 9.57139 25.6514 9.34835C25.4282 9.1253 25.1255 9 24.8098 9C24.4942 9 24.1914 9.1253 23.9682 9.34835L12.1741 21.1222Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckedIcon;
