const Tape5 = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 84 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M83.2329 26.2031C82.7068 24.3884 82.2083 22.5637 81.835 20.7056C81.2026 17.569 78.4972 6.29805 78.0344 4.21248C77.7631 2.95436 77.0968 1.84494 76.1733 1.11357C75.2498 0.38219 74.1397 0.0847684 73.0724 0.282784C65.9654 1.66782 57.77 3.12048 50.5951 4.84134L28.093 10.0533C22.0782 12.1154 11.2001 13.2375 3.85456 14.7914C3.32157 14.9037 2.81981 15.1483 2.38188 15.5092C1.94395 15.8702 1.57965 16.3395 1.31265 16.8866C1.04565 17.4338 0.881924 18.0465 0.832102 18.685C0.78228 19.3235 0.84748 19.9735 1.02347 20.5927C2.25848 24.9552 4.36081 37.5081 5.59753 41.8563C6.46566 44.9038 8.76547 48.0744 14.0202 46.7525C21.6892 44.8249 31.8422 43.385 38.2216 42.061L60.0314 36.2071C60.8888 35.7761 62.3733 35.6925 63.5142 35.3234C68.661 33.6567 77.0431 33.4881 82.2079 31.8791C84.4535 31.1774 84.0549 29.0349 83.2329 26.2031ZM24.0712 15.7173C19.6405 16.7173 10.3254 18.622 5.7417 18.8563C10.5197 17.7462 20.0582 15.646 24.9299 14.5168C24.9526 14.4788 24.9753 14.4412 24.9989 14.4078L25.0805 14.483L24.9299 14.5168C24.6488 14.9708 24.4181 15.638 24.0712 15.7173ZM59.2433 29.5707L58.7397 28.7169C63.066 27.689 67.3921 26.6603 71.718 25.6308L72.3058 26.6162C67.9515 27.6001 63.5974 28.5852 59.2433 29.5707ZM77.5361 28.5711L71.1623 29.894C71.1154 29.6529 71.0702 29.4115 71.0246 29.1675L77.8532 27.7503L77.5361 28.5711Z"
        fill="#499F99"
      />
    </svg>
  );
};

export default Tape5;
