const AccountIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.95339"
        cy="7.27273"
        r="2.27273"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M21.3325 12H18.8106C18.3948 12 18.0203 12.2628 17.8732 12.6518C17.3654 13.9944 15.9702 14.8088 14.5552 14.5675L14.4961 14.5574C14.0847 14.4872 13.673 14.6799 13.4633 15.0408L12.3456 16.9646C12.1386 17.3208 12.1792 17.769 12.4468 18.0823L12.4908 18.1337C13.406 19.2048 13.395 20.7857 12.4649 21.8439C12.1891 22.1577 12.1523 22.6155 12.3744 22.9693L13.7269 25.1238C13.9436 25.469 14.3481 25.6481 14.7494 25.5766L14.8143 25.565C16.1732 25.3228 17.522 26.039 18.0824 27.3005L18.1293 27.406C18.2897 27.7672 18.6479 28 19.0432 28H21.3619C21.757 28 22.1118 27.7578 22.2556 27.3898C22.7379 26.1557 24.0003 25.4102 25.3139 25.5839L25.6355 25.6264C25.9483 25.6677 26.2546 25.5137 26.4078 25.2379L27.1292 23.9399C27.6506 23.0017 27.5209 21.8363 26.8061 21.0356C26.4223 20.6058 26.3788 19.9705 26.7003 19.4924L27.6477 18.0833C27.8629 17.7632 27.8748 17.3479 27.6783 17.016L26.498 15.0221C26.2907 14.6718 25.889 14.4854 25.4876 14.5531L25.3441 14.5773C23.9678 14.8096 22.6321 13.9874 22.2195 12.654C22.0991 12.2652 21.7395 12 21.3325 12Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M22.4548 20.1017C22.4548 21.4136 21.3799 22.4915 20.036 22.4915C18.6921 22.4915 17.6172 21.4136 17.6172 20.1017C17.6172 18.7898 18.6921 17.7119 20.036 17.7119C21.3799 17.7119 22.4548 18.7898 22.4548 20.1017Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2396 13.2275C11.5446 12.9064 10.7705 12.7273 9.95455 12.7273C6.94208 12.7273 4.5 15.1693 4.5 18.1818V22.5454C4.5 25.5579 6.94208 28 9.95455 28C11.0361 28 12.0442 27.6852 12.8921 27.1422C12.2066 26.7952 11.5778 26.3524 11.0233 25.8315C10.6868 25.9409 10.3276 26 9.95455 26C8.04665 26 6.5 24.4533 6.5 22.5454V18.1818C6.5 16.2739 8.04665 14.7273 9.95455 14.7273C10.1236 14.7273 10.2898 14.7394 10.4523 14.7629C10.9682 14.1677 11.5705 13.6494 12.2396 13.2275Z"
        fill="#767676"
      />
    </svg>
  );
};

export default AccountIcon;
