const Tape8 = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 137 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8" filter="url(#filter0_d_3122_16498)">
        <path
          d="M114.274 71.761C112.933 70.355 111.905 59.6186 111.442 57.7595C114.429 55.0122 117.165 53.6169 118.557 51.0253C118.22 49.7637 114.273 48.994 113.111 48.1473C111.949 47.3007 110.55 46.8477 109.204 46.8821C100.234 47.1757 89.8921 47.3739 80.8358 47.9835L52.434 49.7155C44.8394 50.8279 31.1152 50.3084 21.8443 50.7306C21.1716 50.7608 20.5378 50.9241 19.984 51.2098C19.4301 51.4955 18.9687 51.8972 18.6297 52.3889C18.2906 52.8806 18.0816 53.4512 18.0161 54.0637C17.9506 54.6763 20.598 58.4194 23.7936 61.236C22.4647 65.7716 18.9023 70.2644 17.1766 74.5022C18.2587 77.5892 21.146 81.0076 27.779 80.5014C37.4592 79.7642 50.2703 79.8679 58.3217 79.526L85.8529 77.0683C86.9361 76.7767 88.8087 76.9151 90.2493 76.7254C96.7474 75.8686 107.32 76.9446 113.84 76.1464C116.675 75.7972 115.298 74.6318 114.274 71.761ZM47.338 54.6196C41.7457 54.9351 29.9895 55.4066 24.2074 54.9562C30.2381 54.5851 42.277 53.9568 48.426 53.5811C48.4548 53.5476 48.4836 53.5144 48.5134 53.4855L48.616 53.5705L48.426 53.5811C48.0696 53.9803 47.7758 54.5939 47.338 54.6196ZM88.7897 63.6222L88.1581 62.7188C93.6188 62.3607 95.1763 68.8884 100.637 68.5287L101.374 69.5723C95.8779 69.8835 94.2852 63.3093 88.7897 63.6222ZM107.962 72.2438L99.9178 72.5854C99.8597 72.3444 99.8038 72.1034 99.7472 71.8598L108.366 71.4938L107.962 72.2438Z"
          fill="#DC747E"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3122_16498"
          x="11.1766"
          y="43.8802"
          width="113.381"
          height="45.6714"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3122_16498"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3122_16498"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Tape8;
