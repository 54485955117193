import { FC, useState } from "react";

import { formatDate } from "utilities/DateFormatter";
import CalendarIcon from "assets/icons/CalendarIcon";
import { SupportedLang } from "config/constants";
import ArrowLeftIcon from "assets/icons/ArrowLeftIcon";
import ArrowRightIcon from "assets/icons/ArrowRightIcon";

interface MonthPickerInputProps {
  value: Date;
  onChange: (date: Date) => void;
  disable?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const MonthPickerInput: FC<MonthPickerInputProps> = ({
  value,
  onChange,
  disable = false,
  minDate,
  maxDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const changeCurrentYear = (step: number) => {
    let date = new Date(value);
    date.setFullYear(date.getFullYear() + step);
    if (minDate && date.getTime() < minDate.getTime()) {
      date = minDate;
    }
    if (maxDate && date.getTime() > maxDate.getTime()) {
      date = maxDate;
    }
    onChange(date);
  };

  const renderPreviousYearButton = () => {
    let isVisible = false;
    if (!minDate || (minDate && value.getFullYear() > minDate.getFullYear())) {
      isVisible = true;
    }
    return (
      <button
        type="button"
        className={isVisible ? "visible" : "invisible"}
        onClick={() => changeCurrentYear(-1)}
      >
        <ArrowLeftIcon width={32} height={32} color={"black"} />
      </button>
    );
  };

  const renderNextYearButton = () => {
    let isVisible = false;
    if (!maxDate || (maxDate && value.getFullYear() < maxDate.getFullYear())) {
      isVisible = true;
    }
    return (
      <button
        type="button"
        className={isVisible ? "visible" : "invisible"}
        onClick={() => changeCurrentYear(1)}
      >
        <ArrowRightIcon width={32} height={32} color={"black"} />
      </button>
    );
  };

  const renderMonthOptions = () => {
    const monthButtons = [];
    for (let i = 0; i < 12; i++) {
      const temp = new Date(value);
      temp.setMonth(i);
      const lowerBoundCondition = minDate && temp.getTime() < minDate.getTime();
      const upperBoundCondition = maxDate && temp.getTime() > maxDate.getTime();
      const isDisabled = lowerBoundCondition || upperBoundCondition;
      monthButtons.push(
        <button
          key={temp.getMonth()}
          type="button"
          className={`p-2 border border-[#f8f8f8] rounded-lg ${
            i === value.getMonth() ? "bg-primary text-white" : ""
          } ${isDisabled ? "opacity-50" : "opacity-100"}`}
          onClick={() => {
            const newDate = new Date(temp);
            newDate.setMonth(i);
            onChange(newDate);
          }}
          disabled={isDisabled}
        >
          {formatDate(temp, {
            [SupportedLang.ja]: "Mo",
            [SupportedLang.en]: "MMMM",
          })}
        </button>
      );
    }
    return monthButtons;
  };

  return (
    <div
      className={`w-full h-full space-y-1 relative cursor-pointer ${
        disable ? "bg-white/50 text-[#666666]" : ""
      }`}
    >
      <div
        className="w-full h-full flex justify-between items-center p-3 bg-white rounded-lg border border-[#9B9B9B]"
        onClick={() => !disable && setIsOpen(!isOpen)}
      >
        <p>
          {formatDate(value, {
            [SupportedLang.ja]: "yo Mo",
            [SupportedLang.en]: "yyyy MMMM",
          })}
        </p>
        <CalendarIcon width={32} height={32} color={"#767676"} />
      </div>
      {isOpen && (
        <div className="w-full bg-white rounded-lg p-2 absolute z-40 shadow-lg">
          <div className="flex justify-center space-x-3">
            {renderPreviousYearButton()}
            <h3 className="font-semibold text-xl">
              {formatDate(value, {
                [SupportedLang.ja]: "yo",
                [SupportedLang.en]: "yyyy",
              })}
            </h3>
            {renderNextYearButton()}
          </div>
          <div className="w-full grid grid-cols-3">{renderMonthOptions()}</div>
        </div>
      )}
    </div>
  );
};

export default MonthPickerInput;
