const Flags = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 336 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62 37.5C59 36.5 38.6154 28 31 24L45 50L62 37.5Z"
        fill="#E1BC58"
      />
      <path
        d="M44.203 48.1804L41.8961 44.711L41.1494 42.3252L41 41.848L43.3068 45.3173L44.203 48.1804Z"
        fill="white"
      />
      <path
        d="M55.3393 43.8407L51.7178 43.824L51.5128 43.1693L51.4718 43.0383L55.0934 43.055L55.3393 43.8407Z"
        fill="white"
      />
      <path
        d="M104.5 49C98.5 48.5 70 41 60.5 37L81.5 61L104.5 49Z"
        fill="#DC747E"
      />
      <path
        d="M70.203 44.1804L67.8961 40.711L67.1494 38.3252L67 37.848L69.3068 41.3173L70.203 44.1804Z"
        fill="white"
      />
      <path
        d="M88.0035 45.1859L82.8236 45.1621L82.5305 44.2256L82.4718 44.0383L87.6517 44.0621L88.0035 45.1859Z"
        fill="white"
      />
      <path
        d="M308 22.5C302.195 25.3158 287.354 32.7733 277 36L296 43.4999L308 22.5Z"
        fill="#DC747E"
      />
      <path
        d="M292.48 33.2297L294.941 31.402L296.779 30.6503L297.147 30.5L294.686 32.3277L292.48 33.2297Z"
        fill="white"
      />
      <path
        d="M294.742 44.0682L293.862 40.9483L294.584 40.6532L294.728 40.5942L295.608 43.7142L294.742 44.0682Z"
        fill="white"
      />
      <path
        d="M279.5 35C274 37 247.5 45.0745 237.5 47.5L268.5 59.9255L279.5 35Z"
        fill="#507ABB"
      />
      <path
        d="M251.203 50.1802L248.896 46.7109L248.149 44.3251L248 43.8479L250.307 47.3172L251.203 50.1802Z"
        fill="white"
      />
      <path
        d="M269.003 51.1859L263.824 51.1621L263.53 50.2256L263.472 50.0383L268.652 50.0621L269.003 51.1859Z"
        fill="white"
      />
      <path
        d="M171 55C159.5 56.5 116 50.5 100.5 48.5L138.295 80.5126L171 55Z"
        fill="#499F99"
      />
      <path
        d="M118.422 63.7988L114.896 60.711L114.149 58.3252L114 57.848L117.526 60.9357L118.422 63.7988Z"
        fill="white"
      />
      <path
        d="M147.742 80.1804L138.896 78.7579L138.149 76.3721L138 75.8949L146.846 77.3174L147.742 80.1804Z"
        fill="white"
      />
      <path
        d="M157.149 65.3233L152.11 74.2776L150.204 73.8821L149.823 73.803L154.862 64.8487L157.149 65.3233Z"
        fill="white"
      />
      <path
        d="M34 25.5C30 23.5 7 11.5 -3 0L13.5085 41.5L34 25.5Z"
        fill="#507ABB"
      />
      <path
        d="M26 26.5L25 24.2143V22.7857V22.5L26 24.7857V26.5Z"
        fill="white"
      />
      <path
        d="M14.203 37.9818L11.8961 34.5124L11.1494 32.1266L11 31.6494L13.3068 35.1187L14.203 37.9818Z"
        fill="white"
      />
      <path
        d="M20.3393 37.8406L16.4481 36.9626L16.0747 35.7695L16 35.5309L19.8912 36.4089L20.3393 37.8406Z"
        fill="white"
      />
      <path
        d="M306 23.5C310.5 21.5 329 11.1747 339 3L328.492 33.5L306 23.5Z"
        fill="#499F99"
      />
      <path
        d="M313 24.3374L314 22.7126V21.6971V21.494L313 23.1188V24.3374Z"
        fill="white"
      />
      <path
        d="M328 29.7968L330.307 27.3307L331.054 25.6347L331.203 25.2955L328.896 27.7617L328 29.7968Z"
        fill="white"
      />
      <path
        d="M322 33.8204L325.891 33.1963L326.265 32.3482L326.339 32.1786L322.448 32.8027L322 33.8204Z"
        fill="white"
      />
      <path
        d="M243.5 46C224.5 51 186 55 161 55L199.5 86.2301L243.5 46Z"
        fill="#E1BC58"
      />
      <path
        d="M178.778 58.1804L173.896 55.5171L173.149 53.1312L173 52.6541L177.882 55.3174L178.778 58.1804Z"
        fill="white"
      />
      <path
        d="M226.195 66.3592L226.678 67.9041L215.861 70.9608L213.341 69.8172L209.556 70.379L212.857 68.2723L215.633 66.5257L226.195 66.3592Z"
        fill="white"
      />
    </svg>
  );
};

export default Flags;
