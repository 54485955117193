const Tape6 = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 90 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M87.5788 20.786C86.434 19.3509 85.314 17.8946 84.3105 16.3534C82.6143 13.7525 76.0775 4.59501 74.9066 2.88403C74.2082 1.84851 73.1567 1.12095 71.9696 0.851745C70.7824 0.582539 69.5503 0.792276 68.528 1.43759C61.7422 5.79584 53.8687 10.6891 47.1262 15.3847L25.918 29.9416C20.4733 34.446 9.75248 40.2019 2.77987 44.8187C2.27379 45.1532 1.8439 45.5955 1.51813 46.1168C1.19235 46.638 0.977992 47.2266 0.888965 47.8442C0.799938 48.4618 0.838237 49.0947 1.00138 49.7018C1.16451 50.3088 1.44884 50.8764 1.83589 51.3676C4.5576 54.8304 10.9087 65.4253 13.6274 68.8744C15.5342 71.291 18.9428 73.1985 23.8603 69.703C31.0375 64.6031 40.9122 58.8709 46.9762 54.8848L67.263 40.0402C67.9932 39.2725 69.4795 38.5507 70.5197 37.7165C75.2115 33.9519 83.7057 30.1547 88.4352 26.4352C90.4908 24.816 89.3662 23.0249 87.5788 20.786ZM23.7134 36.8821C19.5286 39.7244 10.6645 45.5186 6.06714 47.7253C10.5692 44.6312 19.5957 38.5606 24.1871 35.4084C24.1975 35.3637 24.2081 35.3193 24.2209 35.2785L24.3293 35.3119L24.1871 35.4084C24.0525 35.9468 24.0406 36.6587 23.7134 36.8821ZM64.2352 34.2984L63.4354 33.7345C67.5042 30.9121 71.5726 28.089 75.6405 25.2652L76.5703 25.9133C72.4581 28.7075 68.3465 31.5028 64.2352 34.2984ZM82.561 25.4326L76.5022 29.4152C76.3736 29.2146 76.2466 29.0128 76.1183 28.809L82.6094 24.5423L82.561 25.4326Z"
        fill="#E1BC58"
      />
    </svg>
  );
};

export default Tape6;
