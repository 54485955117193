const HideIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9994 23.9324C17.1346 23.9324 18.1713 23.8157 19.1169 23.614L17.0086 21.623C16.681 21.6468 16.3486 21.666 15.9994 21.666C9.57835 21.666 7.09081 17.3077 6.48842 16C6.94075 15.0468 7.55165 14.168 8.29798 13.3971L6.62042 11.8128C4.77486 13.7019 4.07528 15.6045 4.06208 15.6419C3.97931 15.8746 3.97931 16.1265 4.06208 16.3592C4.08728 16.434 6.84002 23.9324 15.9994 23.9324ZM15.9994 8.0676C13.7951 8.0676 11.9843 8.51635 10.4747 9.17927L6.04803 5.00002L4.35127 6.60237L25.9508 27L27.6475 25.3977L23.6648 21.6366C26.8015 19.4257 27.9211 16.4068 27.9379 16.3592C28.0207 16.1265 28.0207 15.8746 27.9379 15.6419C27.9115 15.566 25.1588 8.0676 15.9994 8.0676ZM21.9657 20.032L19.2297 17.4483C19.4577 17.0063 19.5993 16.5202 19.5993 16C19.5993 14.1404 17.9686 12.6004 15.9994 12.6004C15.4486 12.6004 14.9338 12.7341 14.467 12.9506L12.2975 10.9017C13.4885 10.5158 14.7403 10.3238 15.9994 10.334C22.4204 10.334 24.908 14.6923 25.5104 16C25.148 16.7842 24.1112 18.654 21.9657 20.032Z"
        fill={color}
      />
    </svg>
  );
};

export default HideIcon;
