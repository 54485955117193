const Tape3 = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 71 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <g filter="url(#filter0_d_3122_16491)">
          <path
            d="M64.6584 25.3924C64.2661 22.43 63.0874 19.6269 61.2449 17.2743C60.1513 15.8656 59.133 14.4892 57.7265 12.92C51.0928 5.52791 42.5458 3.06299 32.9153 3.0005C26.4517 2.95806 21.0539 5.64055 16.5519 9.64963C5.74899 19.2713 2.55848 35.0916 10.187 48.5716C14.5039 56.1939 21.6707 60.0352 29.5782 61.8243C34.1601 62.8604 39.4178 61.8945 44.1625 60.8657C47.3861 60.085 50.3508 58.4782 52.7647 56.2036C56.741 53.0906 59.2533 49.723 61.7757 45.7035C62.5542 44.4691 63.6659 41.4468 64.9138 34.28C65.0858 31.3156 65.0003 28.342 64.6584 25.3924ZM46.6348 35.6371C46.3871 36.2851 46.0268 37.1384 45.8366 37.5014C45.0057 39.098 43.6822 40.3316 42.3784 41.5679C41.4772 42.4531 40.3602 43.0872 39.1381 43.4072C37.3251 43.8002 35.3219 44.1679 33.5726 43.7725C30.5545 43.0893 27.8195 41.6254 26.1728 38.7149C24.7964 36.3404 24.2861 33.5621 24.7287 30.8535C25.1712 28.1449 26.5394 25.6735 28.5999 23.8606C30.3192 22.3315 32.3802 21.3058 34.8459 21.3231C38.5221 21.3482 41.7828 22.2875 44.3153 25.1092C45.1462 26.0166 45.9161 26.9779 46.6201 27.9869C47.0953 28.9249 47.3625 29.9543 47.4035 31.005C47.4018 32.5806 47.1421 34.1453 46.6348 35.6371ZM51.7397 16.6062C48.7967 14.7744 45.8548 12.941 42.9138 11.1058C43.7098 10.1543 44.5056 9.2028 45.3012 8.25129L53.3865 14.1444C52.8367 14.9647 52.2881 15.7853 51.7409 16.6062H51.7397ZM57.2251 23.1774C56.1889 21.9088 55.1513 20.6401 54.1121 19.3712C54.6151 19.066 55.1166 18.7607 55.6165 18.4555L58.3391 22.3268L57.2251 23.1774Z"
            fill="#EB6D7B"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3122_16491"
          x="0"
          y="0"
          width="71"
          height="71.2687"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3122_16491"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3122_16491"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Tape3;
