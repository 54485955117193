const Tape4 = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 132 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8" filter="url(#filter0_d_3122_16467)">
        <path
          d="M106.007 92.2073C105.254 90.4168 108.106 80.0154 108.334 78.113C112.102 76.6059 115.155 76.2733 117.377 74.3451C117.51 73.0461 114.093 70.9244 113.308 69.7204C112.523 68.5163 111.376 67.5961 110.105 67.1498C101.616 64.2384 91.8778 60.7502 83.1955 58.1033L56.0305 49.6342C48.5361 47.9765 35.8913 42.6162 27.075 39.7179C26.4354 39.5072 25.7849 39.4346 25.1657 39.505C24.5465 39.5753 23.9725 39.787 23.4809 40.1262C22.9894 40.4654 22.5912 40.9245 22.3124 41.4739C22.0336 42.0232 23.1789 46.4626 25.1657 50.2306C22.3124 53.9984 17.3865 56.9329 14.2681 60.2814C14.1832 63.5515 15.668 67.7725 22.0482 69.6553C31.3591 72.4046 43.298 77.0519 50.9458 79.5921L77.5547 87.0736C78.6708 87.1858 80.3722 87.9803 81.7862 88.3147C88.1649 89.8218 97.6657 94.5829 104.045 96.1528C106.819 96.8334 105.946 95.2549 106.007 92.2073ZM49.5249 52.4084C44.1852 50.7171 33.0281 46.9821 27.783 44.5073C33.5523 46.3025 45.0293 49.9912 50.9109 51.8241C50.9497 51.803 50.9884 51.7823 51.0265 51.7658L51.0922 51.8817L50.9109 51.8241C50.4358 52.0707 49.9433 52.5399 49.5249 52.4084ZM85.076 75.5474L84.8065 74.4786C90.0382 76.0835 89.1756 82.7387 94.4076 84.3419L94.726 85.5794C89.478 83.9181 90.3242 77.2069 85.076 75.5474ZM99.9359 90.4168L92.2948 87.8788C92.326 87.6329 92.3594 87.3877 92.3931 87.1399L100.579 89.859L99.9359 90.4168Z"
          fill="#00A199"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3122_16467"
          x="8.26471"
          y="36.4789"
          width="115.116"
          height="68.8306"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3122_16467"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3122_16467"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Tape4;
