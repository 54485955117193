import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import DropdownInput from "components/__reusable/DropdownInput";
import useAppSelector from "hooks/useAppSelector";
import type { DropdownInputOption } from "types/DropdownInputOption";
import type { Class } from "types/Class";

interface NextYearClassDropdownProps {
  currentYear: number;
  currentClassId: string;
  onChange: (classId: number) => void;
}

const NextYearClassDropdown: React.FC<NextYearClassDropdownProps> = ({
  currentYear,
  currentClassId,
  onChange,
}) => {
  const { t } = useTranslation();
  const { classes } = useAppSelector((state) => state.class);
  const [validClasses, setValidClasses] = useState<Class[]>([]);

  useEffect(() => {
    setValidClasses(classes.filter((c) => c.year === currentYear + 1));
  }, [classes, currentYear]);

  const classOptions: DropdownInputOption[] = validClasses.map((c) => {
    let option: DropdownInputOption = {
      label: "",
      value: "",
    };
    option.label = `${c.year} - ${c.name}`;
    option.value = c.id.toString();
    return option;
  });

  return (
    <div className="w-full h-full">
      <DropdownInput
        placeholder={t("_published_picture._plese_select_class")}
        options={classOptions}
        value={currentClassId || ""}
        onChange={(value) => {
          if (isNaN(parseInt(value))) return;
          onChange(parseInt(value));
        }}
      />
    </div>
  );
};

export default NextYearClassDropdown;
