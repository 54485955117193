const ShootingStar = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 114 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.3504 20.7233C75.6608 7.62344 81.336 4.0184 81.336 3.05464C81.336 2.37648 78.766 2.8404 76.8384 3.87552C66.416 9.44376 44.1428 25.2561 44.6784 26.7553C45.1068 28.0401 47.034 27.0409 56.3504 20.7233Z"
        fill="#E1BC58"
      />
      <path
        d="M113.817 -3.7985C113.817 -5.04778 110.855 -6.26138 109.034 -5.76166C107.678 -5.40474 101.788 -1.2999 90.4376 5.87454C79.4796 12.7635 76.9812 14.6552 77.6236 15.6545C78.6232 17.2609 84.0484 13.2275 103.466 2.51934C114.138 -3.40582 113.817 -3.22742 113.817 -3.7985Z"
        fill="#E1BC58"
      />
      <path
        d="M65.9876 21.6509C43.5716 34.3933 48.5332 32.5017 30.9362 41.3181C15.2309 49.2065 13.125 49.7061 7.44965 47.1717C1.09613 44.3165 0.239525 45.1373 2.84517 51.6337C3.98737 54.4889 4.27288 55.5957 4.13008 56.7737C3.80885 59.4505 2.45249 63.3413 1.16749 65.0901C-2.9016 70.7297 5.52213 68.3381 6.16461 68.0529C9.48417 66.6249 12.8394 68.0529 17.6937 72.9429C20.9062 76.1553 25.9034 76.7977 24.6898 73.8353C22.0841 67.4817 22.9051 60.9497 31.4002 53.5613C38.2892 47.6001 48.676 39.3193 57.8848 33.0373C71.3416 23.8281 72.4836 22.8645 71.3772 21.5793C70.6276 20.6873 67.5936 20.7229 65.9876 21.6509ZM24.9753 52.6329C16.8371 60.8069 17.5867 66.3037 17.3725 66.9465C12.7323 64.5549 13.4462 64.7333 7.52101 64.4121L8.44909 62.4133C10.4836 58.0229 8.91313 52.1333 8.91313 52.0977L11.5902 52.9541C16.6944 54.5605 20.9062 53.7753 26.2602 50.2417C27.4024 49.4921 28.4376 48.8497 28.5447 48.8497C28.6875 48.8137 27.0812 50.5273 24.9753 52.6329Z"
        fill="#E1BC58"
      />
    </svg>
  );
};

export default ShootingStar;
