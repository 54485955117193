import { FC } from "react";
import { useTranslation } from "react-i18next";

import DropdownInput from "./DropdownInput";

const START_DATE = new Date(0);
const START_YEAR = START_DATE.getFullYear();
const today = new Date();
const END_YEAR = today.getFullYear() + 1;
let year = START_YEAR;
const YEAR_OPTIONS: number[] = [];
while (year <= END_YEAR) {
  YEAR_OPTIONS.push(year);
  year += 1;
}

interface YearPickerProps {
  value: string;
  onChange: (year: string) => void;
}

const YearPicker: FC<YearPickerProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <DropdownInput
      placeholder={t("_class_form._academic_year")}
      options={YEAR_OPTIONS.map((year) => ({
        value: year.toString(),
        label: year.toString(),
      }))}
      value={value}
      onChange={(value) => onChange(value)}
    />
  );
};

export default YearPicker;
