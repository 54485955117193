import { FC, Fragment, useEffect, useState } from "react";

import { getClasses } from "services/classServices";
import useAppSelector from "hooks/useAppSelector";
import { Class } from "types/Class";
import useAppDispatch from "hooks/useAppDispatch";
import { setViewYear } from "features/class/classSlice";

const YearButton = () => {
  const [classes, setClasses] = useState<Class[]>([]);
  const { viewedYear } = useAppSelector((state) => state.class);
  const dispatch = useAppDispatch();

  const fetchClasses = async () => {
    try {
      const classes = await getClasses();
      setClasses(classes);
    } catch (e) {}
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const renderButtons = () => {
    const yearDict: { [key: number]: boolean } = {};
    for (let c of classes) {
      if (!yearDict[c.year]) yearDict[c.year] = true;
    }

    const yearButtons = [];
    for (let key of Object.keys(yearDict)) {
      yearButtons.push(
        <button
          key={key}
          className={
            key === viewedYear.toString()
              ? "bg-primary px-2 rounded text-white border border-primary"
              : "border border-primary px-2 rounded"
          }
          onClick={() => dispatch(setViewYear({ year: parseInt(key) }))}
        >
          {`${key}年度`}
        </button>
      );
    }
    return yearButtons;
  };

  return <Fragment>{renderButtons()}</Fragment>;
};

export default YearButton;
