const BackIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      stoke={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 17C26.5523 17 27 16.5523 27 16C27 15.4477 26.5523 15 26 15V17ZM5.29289 15.2929C4.90237 15.6834 4.90237 16.3166 5.29289 16.7071L11.6569 23.0711C12.0474 23.4616 12.6805 23.4616 13.0711 23.0711C13.4616 22.6805 13.4616 22.0474 13.0711 21.6569L7.41422 16L13.0711 10.3431C13.4616 9.95262 13.4616 9.31946 13.0711 8.92893C12.6805 8.53841 12.0474 8.53841 11.6569 8.92893L5.29289 15.2929ZM26 15L6 15V17L26 17V15Z"
        fill="black"
      />
    </svg>
  );
};

export default BackIcon;
