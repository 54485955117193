const Tape2 = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 114 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8" filter="url(#filter0_d_3122_16489)">
        <path
          d="M104.893 20.0541C103.331 18.4445 101.798 16.8062 100.404 15.0507C98.0471 12.0886 96.6908 10.5495 95.0715 8.60743C94.1043 7.43082 92.722 6.67082 91.2103 6.48453C89.6986 6.29825 88.1731 6.69992 86.9491 7.60651C78.8318 13.7152 69.3989 20.6041 61.3658 27.1181L36.0792 47.3484C29.6555 53.4754 16.7308 61.7523 8.40231 68.1977C7.79777 68.6649 7.29827 69.2541 6.93628 69.9269C6.57428 70.5997 6.35788 71.3412 6.30114 72.1031C6.2444 72.865 6.34858 73.6303 6.60692 74.3494C6.86526 75.0684 7.27198 75.725 7.80065 76.2766C11.519 80.1653 12.7702 83.475 16.4836 87.3473C19.0878 90.0603 23.5289 91.9753 29.3813 87.1383C37.9234 80.0808 49.7898 71.9333 57.0356 66.3632L81.1419 45.8924C81.9889 44.8717 83.7876 43.8169 85.0172 42.6784C90.5628 37.5409 100.871 31.9115 106.468 26.8244C108.9 24.6102 107.332 22.5652 104.893 20.0541ZM33.9324 56.0535C28.9403 60.0087 18.3458 68.1114 12.7801 71.3426C18.1476 67.0433 28.921 58.5851 34.3952 54.2044C34.4043 54.1488 34.4137 54.0936 34.4261 54.0423L34.5649 54.0701L34.3952 54.2044C34.2743 54.8754 34.3227 55.7428 33.9324 56.0535ZM76.8377 39.2678L75.7853 38.677C80.6339 34.7598 85.4819 30.8417 90.3293 26.9228L91.5521 27.6006C86.6466 31.4887 81.742 35.3781 76.8377 39.2678ZM99.0171 26.3034L91.7777 31.8687C91.5986 31.6399 91.4216 31.4096 91.2428 31.1769L98.9988 25.2146L99.0171 26.3034Z"
          fill="#507ABB"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3122_16489"
          x="0.286072"
          y="3.43994"
          width="113.4"
          height="95.6692"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3122_16489"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3122_16489"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Tape2;
