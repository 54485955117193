const UploadPhotoIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.7976 14.8778V22.6667C23.7976 23.2778 23.297 23.7778 22.6852 23.7778H9.31479C8.703 23.7778 8.20245 23.2778 8.20245 22.6667V9.33333C8.20245 8.72222 8.703 8.22222 9.31479 8.22222H17.1012C17.713 8.22222 18.2136 7.72222 18.2136 7.11111C18.2136 6.5 17.713 6 17.1012 6H8.22469C7.00111 6 6 7 6 8.22222V23.7778C6 25 7.00111 26 8.22469 26H23.7753C24.3653 26 24.9312 25.7659 25.3484 25.3491C25.7656 24.9324 26 24.3671 26 23.7778V14.8778C26 14.2667 25.5106 13.7778 24.8988 13.7778C24.287 13.7778 23.7976 14.2667 23.7976 14.8778ZM17.3793 14.6111L16.5006 12.6667C16.4563 12.5697 16.3851 12.4874 16.2953 12.4298C16.2056 12.3721 16.1011 12.3414 15.9944 12.3414C15.8877 12.3414 15.7833 12.3721 15.6935 12.4298C15.6038 12.4874 15.5326 12.5697 15.4883 12.6667L14.6096 14.6111L12.6518 15.4889C12.5547 15.5331 12.4724 15.6042 12.4147 15.6939C12.357 15.7835 12.3263 15.8879 12.3263 15.9944C12.3263 16.101 12.357 16.2054 12.4147 16.295C12.4724 16.3846 12.5547 16.4558 12.6518 16.5L14.5984 17.3778L15.4772 19.3333C15.5214 19.4303 15.5927 19.5126 15.6824 19.5702C15.7722 19.6279 15.8766 19.6586 15.9833 19.6586C16.09 19.6586 16.1945 19.6279 16.2842 19.5702C16.374 19.5126 16.4452 19.4303 16.4894 19.3333L17.3682 17.3889L19.3259 16.5111C19.423 16.4669 19.5053 16.3958 19.5631 16.3061C19.6208 16.2165 19.6515 16.1121 19.6515 16.0056C19.6515 15.899 19.6208 15.7946 19.5631 15.705C19.5053 15.6154 19.423 15.5442 19.3259 15.5L17.3793 14.6111ZM22.5962 9.4L21.9288 7.93333C21.7842 7.61111 21.317 7.61111 21.1613 7.93333L20.4939 9.4L19.0256 10.0667C18.703 10.2111 18.703 10.6778 19.0256 10.8333L20.4939 11.5L21.1613 12.9667C21.3059 13.2889 21.7731 13.2889 21.9288 12.9667L22.5962 11.5L24.0645 10.8333C24.3871 10.6889 24.3871 10.2222 24.0645 10.0667L22.5962 9.4Z"
        fill={color}
      />
    </svg>
  );
};

export default UploadPhotoIcon;
