const FlowerIcon = ({ width, height, color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 186.000000 166.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,166.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M1060 1644 c-125 -33 -284 -131 -351 -215 l-31 -40 -24 35 c-31 45
       -97 71 -156 62 -69 -11 -153 -63 -226 -140 -146 -155 -211 -366 -149 -489 l24
       -46 -44 -36 c-65 -55 -97 -124 -97 -210 0 -52 5 -80 21 -110 41 -76 124 -135
       211 -150 43 -7 44 -7 37 -41 -21 -115 89 -224 227 -224 45 0 154 32 172 50 15
       15 26 12 26 -5 0 -52 71 -92 142 -81 71 11 218 95 272 155 10 11 16 27 12 36
       -5 13 -17 7 -63 -35 -117 -104 -263 -150 -313 -100 -22 22 -25 42 -10 71 19
       34 -12 32 -74 -5 -118 -71 -231 -69 -310 5 -40 36 -41 39 -40 101 0 35 4 73 8
       85 8 22 6 23 -50 23 -35 0 -75 8 -99 19 -44 20 -110 87 -125 127 -16 41 -11
       135 9 179 19 43 85 109 120 120 28 8 27 26 -4 55 -14 14 -29 44 -36 76 -30
       134 63 336 211 459 62 52 115 75 178 75 46 0 58 -4 83 -29 16 -16 30 -38 30
       -48 3 -43 5 -48 16 -48 7 0 34 27 60 60 63 78 168 157 263 197 61 26 92 32
       165 35 79 4 95 2 135 -19 88 -45 118 -143 64 -208 -35 -41 -21 -54 36 -32 31
       11 77 16 145 17 90 0 105 -3 151 -27 56 -30 109 -84 130 -134 8 -19 14 -59 14
       -91 -1 -102 -53 -188 -156 -255 -75 -49 -81 -68 -25 -68 51 0 89 -15 124 -48
       42 -39 57 -75 57 -132 0 -72 -18 -107 -88 -172 -108 -99 -263 -163 -444 -183
       -44 -5 -58 -10 -58 -22 0 -14 11 -15 76 -10 108 10 186 32 290 83 244 119 330
       300 209 441 -19 23 -54 49 -78 59 l-44 18 58 54 c80 74 114 144 114 237 0 59
       -5 79 -30 123 -58 106 -159 165 -285 166 -41 0 -91 -3 -112 -7 l-36 -8 10 36
       c25 80 -43 180 -144 210 -63 18 -126 18 -198 -1z"
        />
        <path
          d="M950 1199 c-79 -13 -212 -61 -276 -100 -235 -141 -336 -400 -237
       -611 97 -206 315 -268 626 -177 43 13 67 25 67 34 0 19 2 19 -115 -11 -123
       -31 -262 -35 -346 -11 -157 46 -258 216 -231 389 40 260 312 458 629 459 138
       0 200 -21 280 -94 64 -59 87 -108 88 -187 0 -91 -44 -180 -135 -270 -103 -104
       -267 -174 -362 -156 -48 9 -95 48 -103 86 -19 87 133 239 210 210 33 -12 41
       -35 25 -66 -15 -27 -9 -39 15 -30 41 15 37 89 -7 120 -47 33 -114 15 -190 -53
       -84 -74 -110 -166 -65 -232 36 -54 79 -71 167 -66 146 8 300 99 397 236 116
       161 110 320 -16 434 -101 91 -246 124 -421 96z"
        />
      </g>
    </svg>
  );
};

export default FlowerIcon;
